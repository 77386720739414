/* || GENERAL STYLES */

/* html, body, #app, #app>div {
  height: 100%
} */
.leaflet-control-geosearch .results {
  background: transparent;
}
.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: white;
}
.leaflet-touch .leaflet-geosearch-bar form {
  background-color: rgba(4, 15, 27, 0.7);
  padding: 8px;
  border: none;
  border-radius: 10px;
}
.leaflet-touch .leaflet-geosearch-bar form input {
  background-color: rgba(4, 15, 27, 0);
  color: white;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
}
.leaflet-control-geosearch a.reset,
.leaflet-control-geosearch button.reset {
  color: white;
  font-weight: bold;
  position: absolute;
  line-height: 26px;
  padding: 0 8px;
  /* top:20%; */
  right: 0;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: transparent;
  border-radius: 0 4px 4px 0;
  font-size: large;
  margin-top: 2%;
}
.leaflet-control-geosearch button.reset:hover {
  color: #000000;
  background-color: transparent;
}
.cadastrVisible {
  position: relative; 
  z-index: 500;
  color: black;
}
.geoPointVisible {
  position: relative;
  z-index: 500; 
  display: flex; 
  justify-content: center; 
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 10px;
}
.geoPointVisible .rs-form {
  width: 515px;
}
.geoPointVisible .rs-input {
  width: 230px;
  border-radius: 10px;
  text-indent: 10px;
  background-color: rgba(4, 15, 27, 0.7);
  /* height: 46px; */
}
.geoPointVisible button {
  background-color: rgba(4, 15, 27, 0.7);
}
.hidden {
  display: none;
}
#x-coord {
  margin-right: 20px;
}
.inputs {
  background-color:rgba(4, 15, 27, 0.7);
  border-radius: 5px;
  border-color: transparent;
  height: 46px;
}

.cadastr-input-with-btn {
  position: relative;
  display: inline-block;
}
.cadastrVisible form {
  margin-top: 10px;
}
.cadastrVisible input {
  text-indent: 14px;
  width: 400px;
  height: 46px;
  padding: 10px;
  border-radius: 10px;
  border-color: transparent;
  background-color:rgba(4, 15, 27, 0.7);
  color: white;
  font-size: 13px;
  font-weight: 700;
}
.cadastrVisible button {
  /* color: white; */
  color: white;
  position: absolute;
  width: 26px;
  right: 8px;
  top: 13px;
  background-color: transparent;
}
.cadastrVisible button:hover {
  color: black;
}
.cadastrVisible .results {
  display: flex;
  justify-content: center;
}
.cadastrVisible .rs-list {
  width: 400px;
  text-align: start;
  border-radius: 10px;
}

.cadastrVisible .rs-list-item {
  background-color:rgba(4, 15, 27, 0.7);
  cursor: pointer;
}
.cadastrVisible .rs-list-item:hover a {
  color: white;
}
.cadastrVisible .rs-list .rs-list-item-content{
  padding-left: 30px;
}
.cadastrVisible a {
  color: gray;
}

.rs-list-item-content {
  color: white
}
.rs-form-group {
  margin: 0px;
  margin-right: 5px;
}

.w-56 {
  width: 56px;
}
.w-64 {
  width: 64px;
}
.w-256 {
  width: 256px;
}
.h-60 {
  height: 60px;
}
.align-left {
  align-self: "flex-start";
}
.align-right {
  align-self: "flex-end";
}

.full {
  width: 100%;
  height: 100%;
}
.b-0 {
  border-radius: none;
}
.clear-p-m {
  padding: 0px;
  margin: 0px;
}
.m-5 {
  margin: 5px 5px;
}
.m-10 {
  margin: 10px 10px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-12 {
  margin-top: 12px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}

.p-0 {
  padding: 0px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}

.radio-group {
  padding: 8px 12px;
  display: inline-block;
  vertical-align: middle;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

/* || Typography */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-underline {
  text-decoration: underline;
}
.line-height-46 {
  line-height: 46px;
}
.f-24 {
  font-size: 24px;
}
.f-w-600 {
  font-weight: 600;
}
.slim-text {
  font-size: 0.666em;
  color: #97969b;
  font-weight: lighter;
}
.title {
  white-space: nowrap;
  font-size: 1.2em;
}
.centered {
  justify-content: center;
  align-items: center;
}

/* || MAP STYLE */
.leaflet-container {
  width: 100wh;
  height: 100vh;
}
.leaflet-control-geosearch {
  color: #7d7c7a;
  background-color: rgba(4, 15, 27, 0);
  /* color:#323232; */
}
.z-600 {
  z-index: 600;
}
#modalmap {
  height: 400px;
}
#refreshButton {
  width: 400px;
  max-width: calc(100% - 120px);
  padding: 10px;
  z-index: 500;
  border-radius: 10px;
  background-color: rgba(4, 15, 27, 0.7);
  height: 46px;
}
#searchTabs {
  z-index: 500;
  margin-top: 10px;
  /* width: 400px; */
}
.tabButton {
  width: 130px;
  background-color: rgba(4, 15, 27, 0.7);
}
#searchTabsContainer {
  display: flex;
  justify-content: center;
}
#mapButton {
  position: absolute;
  top: 160px;
  left: 10px;
  padding: 10px;
  z-index: 500;
  border-radius: 5px;
  background-color: #0f131a;
}
.topOnMap {
  /* /* position: absolute; */
  top: 10px;
  left: 80px;
  padding: 5px;
  /* z-index: 500; */
  cursor: pointer;
}

/* || SIDEBAR HEADER STYLES */
.top-user-info {
  padding: 6px 0 0 15px;
  background-color: #3c3f43;
  /*  background: '#26B2F5'; */
  /* bordercolor: '#26B2F5'; */
  color: #fff;
}
.lang-flags {
  border-radius: 50%;
  margin: auto;
  padding: 0 0 0 0;
  height: 20px;
  width: 20px;
  text-align: center;
}
/* Placeholder for Avatar */
.img-placeholder {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.img-circle-placeholder {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.preview {
  width: 60px;
  height: 60px;
  margin: 4px 4px 4px 10px;
  background-color: #555;
}
.preview-details {
  width: 500px;
  height: auto;
  background-position: center;
  background-size: cover;
}

/* || Design elemetns highlight */
.color-highlight {
  color: #e3af34;
  /* color: #3C3F43 */
}
.bg-highlight {
  background-color: #e3af34;
}
.attention-color {
  color: #ffb300;
}
.signIn {
  margin: 0 15px 15px 0;
}
.blacked {
  background-color: #000;
}
/* || IMG in Tables and Lists */
.cell-img-preview {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin-top: 2px;
  overflow: hidden;
  display: inline-block;
}

/* || Cart  */
.cart-item-centered {
  justify-content: center;
  align-items: center;
  height: 60px;
}
.cart-title-price {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  font-weight: 600;
  font-size: 1.2em;
}

.d-sign-clickable {
  cursor: pointer;
}
.d-sign-clickable:hover {
  color: skyblue;
  transition: color 0.3s linear;
}
.rs-list-hover {
  max-height: calc(100% - 65px);
}
.d-side-new {
  height: calc(100vh - 56px) !important;
}
.rs-sidenav-subtle {
  height: calc(100vh - 56px) !important;
  overflow-y: auto;
}
.rs-panel-body-fill {
  height: 99%;
}
.new-scroll {
  height: calc(100vh - 330px);
}
.d-order-table-panel {
  overflow-y: auto;
}
::-webkit-scrollbar {
  position: absolute;
  background-color: #a4a9b3;
  border-radius: 4px;
  width: 10px;
}
::-webkit-scrollbar:hover {
  width: 15px;
}
::-webkit-scrollbar-track {
  background: #292d33;
}
::-webkit-scrollbar-thumb {
  background: #a4a9b3;
  border-radius: 4px;
}
.order-cell {
  overflow-y: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.order-cell > div {
  margin-top: 6px;
  padding: 5px;
  min-width: 100%;
  border: solid gray 1px;
  font-weight: 500;
  font-size: 16px;
}
.infolist {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
}
.info-entry {
  float: right;
  font-weight: bolder;
}
html {
  overflow-y: hidden;
}
.force-index {
  z-index: 2000;
  /* position: fixed; */
  /* max-width: 20%; */
}
/* .rs-modal-wrapper {
  height: 50%;
} */
.centered-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-table-cell-header div,
.rs-table-row.rs-table-row-header {
  background: transparent !important;
}
.tour {
  border-radius: 10px !important;
  background: #292d33 !important;
  white-space: pre-line;
}
#sixth-step {
  display: grid;
}
.full {
  color: #a4a9b3;
}
.active-icon {
  color: #34c3ff;
}
.contact-div {
  white-space: pre-wrap;
}
div[data-tour-elem="controls"] {
  width: 50%;
  margin-right: 0;
  margin-left: 50%;
}
.tour-button {
  position: absolute;
  bottom: 0;
  width: fit-content;
  margin-bottom: 24px;
  background-color: #169de0;
}
.rs-picker-date-menu {
  z-index: 40000;
}
.contact-icon {
  font-size: 24px;
}
.text-center {
  font-size: 26px !important;
}
.rs-flex-box-grid {
  align-items: center;
}
.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  justify-content: center;
  margin: auto;
}
/* .rs-icon-size-2x {
  font-size: 10px;
} */
.shrink {
  display: flex;
}
.shrink > div {
  padding: 0;
}
.shrink > div .rs-panel-body {
  padding: 10px;
}
.rs-flex-box-grid-item.rs-flex-box-grid-item-0 {
  margin: auto;
}
#radio-sat {
  height: min-content;
}
#second-step .ps-panel-body {
  padding: 0;
}
.shrink-panel {
  padding: 0;
}
.shrink-panel > .rs-panel-body {
  padding: 0;
}
/* .shrink-panel > .rs-panel-body .rs-flex-box-grid-item-0{
  height: ;
} */

.leaflet-interactive {
  z-index: 100
}